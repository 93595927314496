import React from 'react'
import PostImage from './PostImage'
import { Grid, withStyles } from '@material-ui/core'
import colors from '../utils/colors'

const styles = theme => ({
  root: {
    padding: 32,
    minHeight: '500px',
  },
  header: {
    color: colors.primary.base,
    textAlign: 'center',
    fontSize: 28,
    fontFamily: 'Arvo, sans-serif',
  },
})

class Related extends React.Component {
  render() {
    const { classes, relatedPosts } = this.props
    return (
      <>
        {relatedPosts && (
          <>
            <p className={classes.header}>Related</p>
            <Grid
              container
              spacing={16}
              className={classes.root}
              alignItems="stretch"
            >
              {relatedPosts.map((p, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    md
                    lg
                    key={p.node.title}
                    style={{ height: '100%' }}
                  >
                    <PostImage
                      key={i}
                      post={p.node}
                      clickable
                      fontSize={48}
                      style={{ height: '100%' }}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </>
        )}
      </>
    )
  }
}

export default withStyles(styles)(Related)
