import React from 'react'
import { withStyles } from '@material-ui/core'
import { Link } from 'gatsby'
import moment from 'moment'
import { jpg } from '../utils/strings'

const postImagePostfix = jpg()

const styles = () => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    color: 'white',
    display: 'table',
    backgroundColor: 'black',
  },
  bgImage: {
    backgroundColor: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    pointerEvents: 'all',
  },
  content: {
    zIndex: 3,
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'bottom',
    display: 'table-cell',
    paddingBottom: 30,
    pointerEvents: 'none',
  },
  category: {
    fontStyle: 'italic',
    fontFamily: 'Aleo, slab-serif',
    fontSize: 20,
    margin: '0',
    pointerEvents: 'none',
  },
  title: {
    fontFamily: 'Oswald, sans',
    fontWeight: 'lighter',
    margin: '0',
    pointerEvents: 'none',
  },
  date: {
    fontFamily: 'Aleo, slab-serif',
    fontSize: 14,
    margin: '0',
    pointerEvents: 'none',
  },
  top: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: '300px',
    zIndex: 2,
    pointerEvents: 'none',
    background: 'linear-gradient(rgba(15, 15, 15, 0.3), rgba(0, 0, 255, 0))',
  },
})

const PostImage = props => {
  const { post, classes, clickable, top, fontSize = 84 } = props
  const child = (
    <div className={classes.root}>
      <div
        onMouseOver={e => {
          if (clickable) {
            e.target.style.filter = 'blur(3px)'
            document.getElementById(
              `${post.titleUrl}_content`
            ).style.backgroundColor = '#00000033'
          }
        }}
        onMouseLeave={e => {
          e.target.style.filter = ''
          document.getElementById(
            `${post.titleUrl}_content`
          ).style.backgroundColor = '#00000000'
        }}
        className={classes.bgImage}
        style={{
          backgroundImage: `url("${
            post.headerImage ? post.headerImage.file.url : ''
          }${postImagePostfix}")`,
        }}
      />
      {top && <div className={classes.top} />}
      <div className={classes.content} id={`${post.titleUrl}_content`}>
        <div className={classes.category}>
          {post.category ? post.category.displayText : ''}
        </div>
        <div className={classes.title} style={{ fontSize }}>
          {post.title}
        </div>
        <div className={classes.date}>
          {moment(post.postDate).format('MMMM DD, YYYY')}
        </div>
      </div>
    </div>
  )
  return clickable ? (
    <Link to={`/post/${post.titleUrl}`}>{child}</Link>
  ) : (
    <>{child}</>
  )
}

export default withStyles(styles)(PostImage)
