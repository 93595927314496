import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'
import PostImage from '../components/PostImage'
import NavBar from '../components/NavBar'
import Related from '../components/Related'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { jpg } from '../utils/strings'
import colors from '../utils/colors'

const bodyImagePostfix = jpg()

const styles = theme => ({
  postHeader: {
    height: '70vh',
  },
  root: {
    width: '100%',
  },
  mdContent: {
    fontFamily: 'Aleo, slab-serif',
    color: '#212121',
    fontSize: '18px',
    '& a': {
      color: 'gray',
    },
    '& img': {
      width: '100%',
    },
    '& hr': {
      color: colors.secondary.base,
    },
  },
  content: {
    maxWidth: '800px',
    margin: '0 auto',
  },
})

const buildPost = post => {
  var content = post.bodyText.bodyText
  var recipe = post.recipe ? post.recipe.internal.content : ''
  if (recipe) {
    return content + `\n\n---\n\n## Recipe\n\n### ${post.title}\n\n${recipe}`
  } else {
    return content
  }
}

const Post = props => {
  const { classes, data } = props
  const post = data.post
  const related = data.related ? data.related.edges : null
  return (
    <Layout>
      <div className={classes.root}>
        <NavBar />
        <div className={classes.postHeader}>
          <PostImage post={post} />
        </div>
        <div className={classes.content}>
          <ReactMarkdown
            source={buildPost(post)}
            className={`${classes.mdContent} md-container`}
            renderers={{
              paragraph: props => {
                let { children } = props
                children = children.filter(v => {
                  return !!(v instanceof Object && v.props.src)
                })
                if (
                  children.length &&
                  children[0] instanceof Object &&
                  children[0].props.src
                ) {
                  if (children.length > 1) {
                    return (
                      <Grid container spacing={16}>
                        {children.map((img, i) => {
                          return (
                            <Grid item md={6} key={img.props.src}>
                              <img
                                src={img.props.src + bodyImagePostfix}
                                alt={img.props.alt || `post image ${i}`}
                              />
                            </Grid>
                          )
                        })}
                      </Grid>
                    )
                  } else {
                    const img = children[0]
                    return (
                      <img
                        src={img.props.src + bodyImagePostfix}
                        alt={img.props.alt || 'post image'}
                        style={{ width: '100%' }}
                      />
                    )
                  }
                } else {
                  return <p>{props.children}</p>
                }
              },
              image: props => {
                return null
              },
            }}
          />
        </div>
        <Related relatedPosts={related} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query myQuery($titleUrl: String!, $category: String!) {
    post: contentfulBlogPost(titleUrl: { eq: $titleUrl }) {
      title
      titleUrl
      headerImage {
        file {
          url
        }
      }
      category {
        displayText
        url
      }
      postDate
      bodyText {
        bodyText
      }
      recipe {
        internal {
          content
        }
      }
    }
    related: allContentfulBlogPost(
      filter: {
        category: { url: { eq: $category } }
        titleUrl: { ne: $titleUrl }
      }
      limit: 3
    ) {
      edges {
        node {
          title
          titleUrl
          headerImage {
            file {
              url
            }
          }
          category {
            displayText
            url
          }
          postDate
          bodyText {
            bodyText
          }
        }
      }
    }
  }
`

export default withStyles(styles)(Post)
